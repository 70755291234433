<template>
    <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.updateBundle') + ": " + Stock.bundleno }}</v-card-title>
            <v-card-text>
                <v-overlay :value="loading.stock" absolute opacity="0.15">
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular color="primary" indeterminate size="40" width="6"/>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="stockBundleForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allProductGroups"
                                :rules="[...validationRules.required]"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="ProductGroup.id"
                                item-text="ProductGroup.title"
                                menu-props="auto"
                                solo
                                v-model="Stock.productgroup_id"
                                @change="filterOtherOptions($event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.species') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="filteredSpecies"
                                :rules="[...validationRules.required]"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="Species.id"
                                item-text="Species.title"
                                menu-props="auto"
                                solo
                                v-model="Stock.species_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="allCertifications"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Certification.title"
                                item-value="Certification.id"
                                menu-props="auto"
                                solo
                                v-model="Stock.certification_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.grade') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="filteredGrades"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Grade.title"
                                item-value="Grade.id"
                                menu-props="auto"
                                solo
                                v-model="Stock.grade_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.spec') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-combobox
                                :items="filteredSpecs"
                                :placeholder="$t('message.startTypingToSearch')"
                                :value="Spec.title"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Spec.title"
                                item-value="Spec.id"
                                return-object
                                menu-props="auto"
                                solo
                                @change="updateSpecSelected($event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.mc') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="allMoistureContent"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Mc.title"
                                item-value="Mc.id"
                                menu-props="auto"
                                solo
                                v-model="Stock.mc_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.thickness') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="filteredThickness"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Thickness.title"
                                item-value="Thickness.id"
                                menu-props="auto"
                                solo
                                v-model="Stock.thickness_id"
                            >
                                <template v-slot:item="{item}">{{ item.Thickness.title + (item.Thickness.suffix != null ? item.Thickness.suffix : '') }}</template>
                                <template v-slot:selection="{item}">{{ item.Thickness.title + (item.Thickness.suffix != null ? item.Thickness.suffix : '') }}</template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.width') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="Stock.width"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.width = $event"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.length') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="Stock.length"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.length = $event"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.pcs') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="Stock.piece"
                                :rules="[...validationRules.required, ...validationRules.number]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details
                                solo
                                type="number"
                                @change="Stock.piece = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.quantity') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :value="Stock.volume"
                                    :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.volume = $event"
                                />
                                <v-select
                                    :items="filteredUnits"
                                    :rules="[...validationRules.required]"
                                    class="ml-2"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Measurement.id"
                                    item-text="Measurement.title"
                                    solo
                                    v-model="Stock.soldmeasurement_id"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyingPrice') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="Stock.costprice"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.costprice = $event"
                                />
                                <span class="font-md ml-3">{{ stockOrderCurrencyCode }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.importDuty') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="Stock.importduty"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.importduty = $event"
                                />
                                <span class="font-md ml-3">{{ stockOrderCurrencyCode }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.storageCost') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="Stock.warehousecost"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.warehousecost = $event"
                                />
                                <span class="font-md ml-3">{{ stockOrderCurrencyCode }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.freightCost') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="Stock.forwardingcharge"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.forwardingcharge = $event"
                                />
                                <span class="font-md ml-3">{{ stockOrderCurrencyCode }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesPrice') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="Stock.sellingprice"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock.sellingprice = $event"
                                />
                                <span class="font-md ml-3">{{ stockOrderCurrencyCode }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn :loading="loading.save" color="info" small @click.stop="saveBundle">{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {generateDimension, generateSpec, isObject} from "Helpers/helpers";
import _ from 'lodash';

export default {
    name: "SalesOrderBundleUpdate",
    props: ['itemIndex','msId','currencyId','dialog', 'updateMode'],
    data(){
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            filteredGrades: [],
            filteredSpecs: [],
            filteredSpecies: [],
            filteredThickness: [],
            filteredUnits: [],
            loading: {
                stock: false,
                save: false
            },
            oldSpecValues: {},
            oldValues: {},
            Certification: {
                title: null
            },
            Grade: {
                title: null
            },
            Mc: {
                title: null
            },
            Productgroup: {
                title: null
            },
            Spec: {
                title: null
            },
            Species: {
                title: null
            },
            Stock: {
                bundleno: null,
                certification_id: null,
                costprice: 0,
                description: null,
                forwardingcharge: 0,
                grade_id: null,
                importduty: 0,
                length: null,
                mc_id: null,
                piece: 0,
                productgroup_id: null,
                salesdescription: null,
                sellingprice: 0,
                size: null,
                soldmeasurement_id: null,
                spec_id: null,
                species_id: null,
                thickness_id: null,
                volume: 0,
                warehousecost: 0,
                width: null
            },
            validForm: false
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapMultiRowFieldsPrefixed('salesorderitem',{
            SalesOrderItems: 'SalesOrderItems',
        },'statePrefix'),
        ...mapGetters('certification', {
            allCertifications: 'allCertifications',
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup', {
            allProductGroups: 'allProductGroups',
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'uofmQty',
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        stockOrderCurrencyCode () {
            const currency = this.allCurrencies.find(c => c.Currency.id === this.currencyId)
            if(currency) return currency.Currency.code
            return ''
        }
    },
    methods: {
        ...mapActions('certification', {
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        filterGradesByProduct(productId){
            return new Promise((resolve, reject) => {
                try {
                    const filteredGrades = this.allGrades.filter(grade => {
                        return grade.Grade.productgroups.includes(productId)
                    })
                    if(filteredGrades.length > 0) {
                        resolve(filteredGrades)
                    } else {
                        resolve([])
                    }
                } catch(error){
                    reject(error)
                }
            })
        },
        filterSpeciesByProduct(productId){
            return new Promise((resolve, reject) => {
                try {
                    const filteredSpecies = this.allSpecies.filter(species => {
                        return species.Species.productgroups.includes(productId)
                    })
                    if (filteredSpecies.length > 0) {
                        resolve(filteredSpecies)
                    } else {
                        resolve([])
                    }
                } catch(error){
                    reject(error)
                }
            })
        },
        filterSpecsByProduct(productId){
            return new Promise((resolve, reject) => {
                try {
                    const filteredSpecs = this.allCleanedSpecs.filter(spec => {
                        return spec.Spec.productgroups.includes(productId)
                    })
                    if(filteredSpecs.length > 0) {
                        resolve(filteredSpecs)
                    } else {
                        resolve([])
                    }
                } catch(error){
                    reject(error)
                }
            })
        },
        filterOtherOptions(productId) {
            this.filterSpeciesByProduct(productId)
                .then((species) => {
                    this.filteredSpecies = species
                    this.filterGradesByProduct(productId)
                        .then((grades) => {
                            this.filteredGrades = grades
                            this.filterSpecsByProduct(productId)
                                .then((specs) => {
                                    this.filteredSpecs = specs
                                })
                                .catch((error) => {
                                    this.$toast.error(error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch((error) => {
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        filterThickness(ms) {
            return new Promise((resolve, reject) => {
                try {
                    const various = this.allThickness.find(t => {
                        return t.Thickness.ms === ms && t.Thickness.title === 'various'
                    })
                    const thicknesses = this.allThickness.filter(t => {
                        return t.Thickness.ms === ms && t.Thickness.title !== 'various'
                    })
                    thicknesses.sort((a,b) => {
                        let strA = a + ''
                        let strB = b + ''
                        return strA.localeCompare(strB, undefined, { numeric: true })
                    })
                    if(various){
                        resolve( [various,...thicknesses])
                    } else {
                        resolve(thicknesses)
                    }
                } catch(error) {
                    reject(error)
                }
            })
        },
        filterUnits(ms) {
            return new Promise((resolve, reject) => {
                try {
                    const filteredUnits = this.uofmQty.filter(u => {
                        return u.Measurement.system === ms || u.Measurement.system == null
                    })
                    if(filteredUnits.length > 0) {
                        resolve(filteredUnits)
                    } else {
                        resolve([])
                    }
                } catch(error) {
                    reject(error)
                }
            })
        },
        saveBundle () {
            if(this.$refs.stockBundleForm.validate()) {
                let reloadList = false
                this.loading.save = true

                //generate size
                const thicknessId = this.Stock.thickness_id
                const width = this.Stock.width
                const length = this.Stock.length
                const thicknessObj = this.allThickness.find(t => t.Thickness.id === thicknessId)
                const thickness = thicknessObj?.Thickness.title
                let unit = thicknessObj?.Thickness.suffix
                if(unit == null){
                    unit = ''
                }

                const size = generateDimension(thickness,width,length,unit)

                if(this.oldValues.size !== size){
                    this.Stock.size = size
                }

                //generate item description
                const description = this.setItemDescription()
                if(this.oldValues.description !== description){
                    this.Stock.description = description
                    reloadList = true
                }

                //compare some values
                if(this.oldValues.productgroup_id !== this.Stock.productgroup_id) {
                    const productGroup = this.allProductGroups.find(p => p.ProductGroup.id === this.Stock.productgroup_id)
                    if(productGroup) {
                        this.Productgroup.id = productGroup.ProductGroup.id
                        this.Productgroup.title = productGroup.ProductGroup.title
                    }
                    reloadList = true
                }
                if(this.oldValues.species_id !== this.Stock.species_id) {
                    const species = this.allSpecies.find(s => s.Species.id === this.Stock.species_id)
                    if(species){
                        this.Species['id'] = species.Species.id
                        this.Species['title'] = species.Species.title
                    }
                    reloadList = true
                }
                if(this.oldValues.certification_id !== this.Stock.certification_id) {
                    const certification = this.allCertifications.find(c => c.Certification.id === this.Stock.certification_id)
                    if(certification) {
                        this.Certification.id = certification.Certification.id
                        this.Certification.title = certification.Certification.title
                    }
                    reloadList = true
                }
                if(this.oldValues.grade_id !== this.Stock.grade_id) {
                    const grade = this.allGrades.find(g => g.Grade.id === this.Stock.grade_id)
                    if(grade) {
                        this.Grade.id = grade.Grade.id
                        this.Grade.title = grade.Grade.title
                    }
                    reloadList = true
                }
                if(this.oldValues.mc_id !== this.Stock.mc_id) {
                    const mc = this.allMoistureContent.find(m => m.Mc.id === this.Stock.mc_id)
                    if(mc){
                        this.Mc.id = mc.Mc.id
                        this.Mc.title = mc.Mc.title
                    }
                    reloadList = true
                }

                const payload = {
                    index: this.itemIndex,
                    prefix: this.statePrefix,
                    stock: JSON.parse(JSON.stringify(this.Stock)),
                    spec: JSON.parse(JSON.stringify(this.Spec)),
                    species: JSON.parse(JSON.stringify(this.Species)),
                    certification: JSON.parse(JSON.stringify(this.Certification)),
                    grade: JSON.parse(JSON.stringify(this.Grade)),
                    productGroup: JSON.parse(JSON.stringify(this.Productgroup)),
                    mc: JSON.parse(JSON.stringify(this.Mc))
                }

                const updatePromise = new Promise((resolve, reject) => {
                    try {
                        this.$store.commit('salesorderitem/updateSalesOrderItemRow', payload)
                        setTimeout(() => {
                            resolve('success')
                        }, 600)
                    } catch (error) {
                        reject('error')
                    }
                })
                updatePromise
                    .then((status) => {
                        if(status === 'success'){
                            this.$toast.success(this.$t('message.successes.bundleUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            if(this.updateMode === false) reloadList = false
                            this.$emit('update-done', { reloadList : reloadList })
                        } else {
                            this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        setItemDescription() {
            let product = null
            let spec = null
            let mc = null

            let productGroupId = this.Stock.productgroup_id
            let specId = this.Stock.spec_id
            let mcId = this.Stock.mc_id

            let productObj = this.allProductGroups.find(pg => pg.ProductGroup?.id === productGroupId)
            let specObj = this.allCleanedSpecs.find(s => s.Spec.id === specId)
            if(specId === 'NEW'){
                specObj = { Spec: { title: null } } //add new title
            }
            if(typeof specObj === 'undefined'){
                specObj = { Spec: { title: null } }
            }
            let mcObj = this.allMoistureContent.find(mc => mc.Mc.id === mcId)

            if(productObj) product = productObj?.ProductGroup?.title
            if(specObj && specObj?.Spec?.title !== 'na') spec = specObj?.Spec?.title
            if(mcObj) mc = mcObj?.Mc?.title

            let description = generateSpec(product, spec, mc)

            return description.trim()
        },
        updateSpecSelected(selectedSpec) {
            if(selectedSpec) {
                if (isObject(selectedSpec)) {
                    const specObj = this.allSpecs.find(s => s.Spec.id === selectedSpec.Spec.id)
                    if(specObj){
                        this.Spec.id = selectedSpec.Spec.id
                        this.Spec.title = selectedSpec.Spec.title
                        this.Stock.spec_id = selectedSpec.Spec.id
                    }
                } else {
                    //string
                    this.Spec.id = 'NEW'
                    this.Spec.title = selectedSpec
                    this.Stock.spec_id = 'NEW'
                }
            } else {
                this.Stock.spec_id = null
                this.Spec.id = null
                this.Spec.title = null
            }
        }
    },
    watch: {
        dialog(value) {
            if(value === true){
                this.loading.stock = true
                this.$nextTick(() => {
                    const stock = _.cloneDeep(this.SalesOrderItems[this.itemIndex]?.['Stock'])
                    const spec = _.cloneDeep(this.SalesOrderItems[this.itemIndex]?.['Spec'])
                    if(stock) {
                        this.Stock.bundleno = stock.bundleno
                        this.Stock.certification_id = stock.certification_id
                        this.Stock.costprice = stock.costprice
                        this.Stock.description = stock.description
                        this.Stock.forwardingcharge = stock.forwardingcharge
                        this.Stock.grade_id = stock.grade_id
                        this.Stock.importduty = stock.importduty
                        this.Stock.length = stock.length
                        this.Stock.mc_id = stock.mc_id
                        this.Stock.piece = stock.piece
                        this.Stock.productgroup_id = stock.productgroup_id
                        this.Stock.salesdescription = stock.salesdescription
                        this.Stock.sellingprice = stock.sellingprice
                        this.Stock.size = stock.size
                        this.Stock.soldmeasurement_id = stock.soldmeasurement_id
                        this.Stock.spec_id = stock.spec_id
                        this.Stock.species_id = stock.species_id
                        this.Stock.thickness_id = stock.thickness_id
                        this.Stock.volume = stock.volume
                        this.Stock.warehousecost = stock.warehousecost
                        this.Stock.width = stock.width

                        this.Spec.id = spec.id
                        this.Spec.title = spec.title

                        this.oldValues = _.cloneDeep(this.Stock)
                        this.oldSpecValues = _.cloneDeep(this.Spec)

                        this.filterUnits(this.msId)
                            .then((units) => {
                                this.filteredUnits = units
                                this.filterThickness(this.msId)
                                    .then((thickness) => {
                                        this.filteredThickness = thickness
                                        this.filterOtherOptions(this.Stock.productgroup_id)
                                        this.loading.stock = false
                                    })
                                    .catch((error) => {
                                        this.loading.stock = false
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.loading.stock = false
                                this.$toast.error(error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    } else {
                        this.loading.stock = false
                    }
                })
            } else {
                // this.$nextTick(() => {
                //     this.Stock.bundleno = null
                //     this.Stock.certification_id = null
                //     this.Stock.costprice = 0
                //     this.Stock.description = 0
                //     this.Stock.forwardingcharge = 0
                //     this.Stock.grade_id = null
                //     this.Stock.importduty = 0
                //     this.Stock.length = null
                //     this.Stock.mc_id = null
                //     this.Stock.piece = 0
                //     this.Stock.productgroup_id = null
                //     this.Stock.salesdescription = null
                //     this.Stock.sellingprice = 0
                //     this.Stock.size = null
                //     this.Stock.soldmeasurement_id = null
                //     this.Stock.spec_id = null
                //     this.Stock.species_id = null
                //     this.Stock.thickness_id = null
                //     this.Stock.volume = 0
                //     this.Stock.warehousecost = 0
                //     this.Stock.width = null
                //     this.oldValues = {}
                // })
            }
            this.edit_dialog = value
        }
    },
    created () {
        // this.resetSalesOrderItems(this.statePrefix)
        if(this.allSpecies.length === 0) this.getAllSpecies()
        if(this.allGrades.length === 0) this.getAllGrades()
        if(this.allProductGroups.length === 0) this.getAllProductGroups()
        if(this.allCurrencies.length === 0) this.getAllCurrencies()
        if(this.allSpecs.length === 0) this.getAllSpecs()
        if(this.allThickness.length === 0) this.getAllThickness()
        if(this.allCertifications === 0) this.getAllCertifications()
    }
}
</script>

<style>

</style>